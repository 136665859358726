<template lang="">
    <div>
        <b-tabs>
            <b-tab title="Xử lý số liệu">
                <b-overlay
            :show="isLoadingDataProcess"
            :opacity="0.5"
            rounded="sm"
            variant="white"
            > 
            <template #overlay>
                <div class="text-center">
                <ez-icon
                    animation="cylon"
                    icon="ezGolf-icon-clock"
                    size="30"
                />
                <p id="cancel-label" style="font-size: 18px;">Please wait...</p>
                <b-form-input disabled style="min-width:500px" v-model="check" id="range-1"  type="range" :min="min" :max="max"></b-form-input>
                <p>{{ check }}/{{ max }}</p>
                </div>
                </template>
                <b-card class="ml-1 mb-1 mr-1" style="min-height:calc(100vh - 150px)">
                <b-row>
                <b-col cols="2">
                    <b-form-group label="Từ ngày">
                        <date-range-picker
                        v-model="dataProcessing.StartDate"
                        ref="picker"
                        :single-date-picker="true"
                        :ranges="false"
                        opens="right"
                        :time-picker="false"
                        :time-picker-increment="1"
                        :show-dropdowns="true"
                        :auto-apply="true"
                        :locale-data="{
                            format: 'mm/dd/YYYY HH:mm',
                            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            firstDay: 0
                        }"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="2">
                    <b-form-group label="Đến ngày">
                    <date-range-picker
                        v-model="dataProcessing.EndDate"
                        ref="picker"
                        :single-date-picker="true"
                        :ranges="false"
                        opens="right"
                        :time-picker="false"
                        :time-picker-increment="1"
                        :show-dropdowns="true"
                        :auto-apply="true"
                        :locale-data="{
                            format: 'mm/dd/YYYY HH:mm',
                            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            firstDay: 0
                        }"
                        />
                </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group label="Nhóm doanh thu">
                        <v-select
                            v-if="listRevenueCenter"
                            v-model="RevenueCenterSelect"
                            label="RevenueCenterName"
                            multiple
                            :options="listRevenueCenter"
                            :clearable="true"
                            placeholder="Nhóm doanh thu"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="2" class="mt-auto mb-auto">
                    <b-button variant="primary" class="float-right" @click="HandleClickDataProcessing">
                    Xử lý dữ liệu
                    </b-button>
                </b-col>
                </b-row>
            </b-card>
            </b-overlay>
            </b-tab>
            <b-tab title="Cảnh báo">
                <b-row>
            <b-col cols="6" class="list-service-no-select">
                <b-card  class="service" v-if="">
                    <b-card-title class="d-flex justify-content-between">
                        <h5>Dịch vụ chưa cấu hình</h5>
                        <span>Số lượng: {{ CountServiceNoSelect() }}</span>
                    </b-card-title>
                    <div class="list-service">
                        <DxTreeView
                              style="max-height: 500px;height: 500px;overflow-y: auto"
                              id="treeview"
                              :ref="treeViewRef"
                              :width="500"
                              :items="ListAllServiceNoRevenueCenter"
                              :show-check-boxes-mode="showCheckBoxesModeValue"
                              :selection-mode="selectionModeValue"
                              :select-nodes-recursive="selectNodesRecursiveValue"
                              :select-by-click="selectByClickValue"
                              :search-enabled="true"
                              :search-mode="contains"
                              @selection-changed="treeViewSelectionChanged"
                              @content-ready="treeViewContentReady"
                            >
                              <template #item="item">
                                <div>
                                  <span>
                                    {{ item.data.label}}
                                  </span> 
                                </div>
                              </template>
                            </DxTreeView>
                            </div>
                        </b-card>
                    </b-col>
                    <b-col>
                      <b-card  class="service">
                    <b-card-title class="d-flex justify-content-between">
                        <h5>Dịch vụ cấu hình trùng</h5>
                        <span>Số lượng: {{ listServiceDuplicate.length }}</span>
                    </b-card-title>
                    <div class="d-flex">
                      <b-row>
                        <b-col cols="10">
                          <treeselect
                            v-model="listRevenueCenterCheckDuplicate"
                            :options="listRevenueCenter"
                            :default-expand-level="1"
                            :clearable="false"
                            class="d-tree-select"
                            :placeholder="$t('golf_caddy_calendar_enter_code_caddy')"
                            :multiple="true"
                            :limit-text="fnCount"
                            :limit="1"
                            :clear-on-select="false"
                          />
                        </b-col>
                        <b-col cols="2">
                          <b-button variant="primary" @click="getListServiceDuplicate()">
                            Kiểm tra
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="list-service mt-1" style="max-height: 450px;height: 450px;overflow-y: auto">
                        <div v-for="(item,index) in listServiceDuplicate" :key="index +'duplicate'" class="border-bottom">
                            <p>Dịch vụ: {{ item.ServiceName }} - Khu vực kinh doanh: {{ item.ZoneName }}</p>
                            <p>Nhóm doanh thu cấu hình: {{ item.RevenueCenter }}</p>
                        </div>
                    </div>
                </b-card>
            </b-col>
                </b-row>
              </b-tab>
        </b-tabs>
        
        
    </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import DxTreeView from 'devextreme-vue/tree-view'
import { reportSetting } from '@/api/Report-Setting'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'devextreme/dist/css/dx.light.css'
import { commonServices } from '@/api/common-services'
const treeViewRef = 'treeView'
export default {
    components: {
        DxTreeView,
        Treeselect
    },
    data() {
        const selectionModes = ['multiple', 'single']
        const showCheckBoxesModes = ['normal', 'selectAll', 'none']
        return {
            selectedService: [],
            showCheckBoxesModes,
            selectionModes,
            showCheckBoxesModeValue: 'normal',
            selectionModeValue: selectionModes[0],
            isSelectionModeDisabled: false,
            isRecursiveDisabled: false,
            selectNodesRecursiveValue: true,
            selectByClickValue: true,
            treeViewRef,
            dataProcessing: {
            StartDate: this.getUTC('start', 'day'),
            EndDate:this.getUTC('start', 'day'),
            RevenueCenterId: null
            },
            listRevenueCenter: null,
            RevenueCenterSelect: null,
            min: null,
            max: null,
            check: null,
            isLoadingDataProcess: false,
            ListAllServiceNoRevenueCenter: [],
            ListServiceNoSelect : null,
            listServiceDuplicate: [],
            listRevenueConfigDuplicate: null,
            listRevenueCenterCheckDuplicate: null,
            listRevenueCenter: null
        }
    },
    computed: {
        treeView() {
        return this.$refs[treeViewRef].instance
        },
    },
    created() {
        this.getListRevenueCenter()
        this.GetRCOM01()
        this.getListServiceNoRevenueCenter()
    },
    methods: {
        async HandleClickDataProcessing() {
            const processCode = this.generateUUID() 
            if(this.dataProcessing.StartDate === this.dataProcessing.EndDate ) {
                this.min = 0
                this.max = 1
                this.check = 0
                this.isLoadingDataProcess = true
                var listRevenueCenterSelectId = []
                if(this.RevenueCenterSelect) {
                    this.RevenueCenterSelect.forEach(x => {
                    listRevenueCenterSelectId = [...listRevenueCenterSelectId, ...x.RevenueCenterChild.map(y => y.Id)]
                    })
                }
                const body = {
                    StartDate: this.dataProcessing.StartDate,
                    RStartDate: this.dataProcessing.StartDate,
                    EndDate: this.dataProcessing.EndDate,
                    ProcessDataCode: processCode,
                    REndDate: this.dataProcessing.EndDate,
                    RevenueCenterId : listRevenueCenterSelectId
                }

                await reportSetting.api_RRCC00(body).then(async res => {
                    await reportSetting.api_RRCC00E({
                            Date: this.dataProcessing.StartDate
                        })
                        await reportSetting.api_RRCC00D(body)
                    this.check += 1
                    if(this.check === this.max) {
                        this.isLoadingDataProcess = false
                        this.showToast('success', this.$t('golf_common_success'))
                    }
                })
            }  else {
                var listRevenueCenterSelectId = []
                if(this.RevenueCenterSelect) {
                    this.RevenueCenterSelect.forEach(x => {
                    listRevenueCenterSelectId = [...listRevenueCenterSelectId, ...x.RevenueCenterChild.map(y => y.Id)]
                    })
                }
                
                var numberDate = (this.dayjs(this.dataProcessing.EndDate).utc().tz(localStorage.getItem('timeZone')) - this.dayjs(this.dataProcessing.StartDate).utc().tz(localStorage.getItem('timeZone')))/86400000 + 1
                this.min = 0
                this.max = numberDate
                this.check = 0
                this.isLoadingDataProcess = true
                for (let i = 0;i<numberDate; i++) {
                    
                    if (i == 0) {
                        var dateTemp = this.dayjs(this.dataProcessing.StartDate).utc().tz(localStorage.getItem('timeZone'))
                        .add(-(localStorage.getItem('timeZoneOffset') / 60), 'hour')
                        .format('YYYY-MM-DDTHH:mm:ss')
                        var body = {
                            StartDate: dateTemp +'Z',
                            RStartDate: this.dataProcessing.StartDate,
                            EndDate: dateTemp +'Z',
                            ProcessDataCode: processCode,
                            REndDate: this.dataProcessing.EndDate,
                            RevenueCenterId : listRevenueCenterSelectId
                        }
                    } else {
                        var dateTemp = this.dayjs(this.dataProcessing.StartDate).utc().tz(localStorage.getItem('timeZone'))
                        .add(i,'day')
                        .add(-(localStorage.getItem('timeZoneOffset') / 60), 'hour')
                        .format('YYYY-MM-DDTHH:mm:ss')
                        var body = {
                            StartDate: dateTemp +'Z',
                            RStartDate: this.dataProcessing.StartDate,
                            REndDate: this.dataProcessing.EndDate,
                            ProcessDataCode: processCode,
                            EndDate: dateTemp +'Z',
                            RevenueCenterId : listRevenueCenterSelectId
                        }
                        
                    }
                    await reportSetting.api_RRCC00(body).then(async res => {
                        await reportSetting.api_RRCC00E({
                            Date: dateTemp
                        })
                        await reportSetting.api_RRCC00D(body)
                        this.check += 1
                        if(res.Status != '200') {
                            this.showToast('error', `Xử lý số liệu lỗi ngày ${ convertUTC(dateTemp,'DD/MM/YYYY') }`)
                        }
                        if(this.check === this.max) {
                            this.isLoadingDataProcess = false
                            this.showToast('success', this.$t('golf_common_success'))
                        }
                    })
                }
            } 
            
        },
        generateUUID() { // Public Domain/MIT
            var d = new Date().getTime();//Timestamp
            var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16;//random number between 0 and 16
                if(d > 0){//Use timestamp until depleted
                    r = (d + r)%16 | 0;
                    d = Math.floor(d/16);
                } else {//Use microseconds since page-load if supported
                    r = (d2 + r)%16 | 0;
                    d2 = Math.floor(d2/16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        },
        treeViewSelectionChanged(e) {
         this.syncSelection(e.component)
        },

        treeViewContentReady(e) {
        this.syncSelection(e.component)
        },

        showCheckBoxesModeValueChanged(e) {
        if (e.value === 'selectAll') {
            this.selectionModeValue = 'multiple'
            this.isRecursiveDisabled = false
        }
        this.isSelectionModeDisabled = e.value === 'selectAll'
        },

        selectionModeValueChanged(e) {
        if (e.value === 'single') {
            this.selectNodesRecursiveValue = false
            this.treeView.unselectAll()
        }
        this.isRecursiveDisabled = e.value === 'single'
        },
        treeViewSelectionChangedNoService(e) {
        this.syncSelectionNoService(e.component)
        },
        syncSelection(treeView) {
            const selectedService = treeView.getSelectedNodes()
                .map(node => node.itemData)
            this.ListServiceNoSelect = selectedService
            },

        treeViewContentReadyNoService(e) {
        this.syncSelectionNoService(e.component)
        },

        syncSelectionNoService(treeView) {
        const selectedService = treeView.getSelectedNodes()
            .map(node => node.itemData)
        this.ListServiceNoSelect = selectedService
        },
        async GetRCOM01() {
            // await commonServices
            // .getCommon({ KeyGroup: 'BOOKING_CONFIG' })
            // .then(res => {
            //     if (res) {
            //     this.listRevenueConfigDuplicate = res?.Data.find(x => x.KeyCode === 'LIST_REVENUE_CONFIG').KeyValue
            //     this.getListServiceDuplicate()
            //     }
            // })
        },
        // async getListServiceDuplicate() {
        //     const body = {
        //         RevenueCenterId : [],
        //         RevenueCenterCode : JSON.parse(this.listRevenueConfigDuplicate)
        //     }
        //     await reportSetting.api_RRCS04(body).then(res => {
        //         this.listServiceDuplicate = res.Data.Service
        //     })
        // },
        async getListServiceDuplicate() {
            let list = []
            if(this.listRevenueCenterCheckDuplicate) {
                this.listRevenueCenterCheckDuplicate.forEach(x => {
                if(this.listRevenueCenter.find(y => y.Id == x)) {
                    list = [...list, ...this.listRevenueCenter.find(y => y.Id == x).children.map(z => z.Id) ]
                } else {
                    list.push(x)
                }
                })
            }
            const body = {
                RevenueCenterId : list,
                RevenueCenterCode : []
            }
            await reportSetting.api_RRCS04(body).then(res => {
                this.listServiceDuplicate = res.Data.Service
            })
            },
        CountServiceNoSelect() {
            let i = 0
            this.ListAllServiceNoRevenueCenter.forEach(x => {
                i += x.Service.length
            })
            return i
        },
        async getListServiceNoRevenueCenter() {
            await reportSetting.api_RCS03().then(res => {
                res.Data.Zone.forEach(x => {
                    x.label = x.ZoneName,
                    x.items = [],
                    x.Service.forEach( y => {
                        x.items.push(
                            {
                                label: y.ServiceName,
                                ServiceId: y.ServiceId
                            }
                        )
                    })
                })
                this.ListAllServiceNoRevenueCenter = res.Data.Zone
            })
        },
        async getListRevenueCenter() {
            await reportSetting.api_RRCE01().then(res => {
                var listRevenueCenterTemp = []
            res.Data.RevenueCenter.forEach(x => {
            x.id = x.Id
            x.label = x.RevenueCenterName
            if(!x.ParentId) {
                listRevenueCenterTemp.push(
                {
                    id: x.Id,
                    label: x.RevenueCenterName,
                    Id:x.Id,
                    ParentId:x.ParentId,
                    RevenueCenterName:x.RevenueCenterName,
                    RevenueCenterCode:x.RevenueCenterCode,
                    Note:x.Note,
                    RevenueCenterChild: res.Data.RevenueCenter.filter(y => y.ParentId === x.Id),
                    children: res.Data.RevenueCenter.filter(y => y.ParentId === x.Id)
                }
                )
            }
            })
            this.listRevenueCenter = listRevenueCenterTemp
        })
        },
    }
}
</script>
<style lang="scss">
    
</style>